import React from "react"
import { document } from "browser-monads"
import JotformEmbed from "react-jotform-embed"

import "../css/team.css"

// Reset Scroll
document.body.scrollTo(0, 0)

const Onboarding = () => (
  <JotformEmbed src="https://form.jotform.com/202514797263156" />
)

export default Onboarding
